import {DataContainer, Feature, FeatureStatus, MiniAction, submitMerge, submitMiniAction, submitMove, trackOnNextPageImpression} from "@otto-ec/tracking-bct";
import {getPagecluster} from "@otto-ec/global-resources/misc";

export class TrackingService {

    sendViewTrackingEvent(featureOrder: number, featureIndex: number, featureTrackingTileLabels: DataContainer): void {
        const trackingData = [
            this.createTopicPromoFeature(featureOrder, featureIndex, "loaded"),
            this.createTopicPromoTileFeature(featureIndex, featureTrackingTileLabels, "loaded")
        ];
        submitMerge({}, trackingData)
    }

    sendClickTrackingEvent(featureOrder: number, featureIndex: number, featureTrackingTileLabels: DataContainer, comboType: string | null): void {
        const featureTrackingData = {
            name: "click",
            features: [
                this.createTopicPromoFeature(featureOrder, featureIndex, "clicked"),
                this.createTopicPromoTileFeature(featureIndex, featureTrackingTileLabels, "clicked")]
        }
        const labelTrackingData = this.buildClickTrackingLabels(featureTrackingTileLabels, comboType);
        submitMove(labelTrackingData, featureTrackingData)
        trackOnNextPageImpression({"promo_Click": ["TopicPromotionLarge"]})
    }

    sendMiniScrollAction(featureIndex: number) {
        const feature = this.createTopicPromoFeatureMiniAction(featureIndex);
        const tileFeature = this._createTopicPromoTileFeatureMiniAction(featureIndex)
        submitMiniAction([feature, tileFeature])
    }

    private buildClickTrackingLabels(featureTrackingTileLabels: { promo_Source?: string[] }, comboType: string | null): DataContainer {
        let attributionSource: string;
        if (featureTrackingTileLabels.promo_Source) {
            attributionSource = featureTrackingTileLabels.promo_Source[0];
        } else {
            attributionSource = "TopicPromotionLarge";
        }

        const pageCluster = getPagecluster();
        const clickTrackingInfo: DataContainer = {
            "wk.promo_AttributionFeature": [`TopicPromotionLarge_${pageCluster}`],
            "wk.promo_AttributionSource": [attributionSource]
        }
        if (comboType) {
            clickTrackingInfo.promo_ComboType = [comboType];
        }
        return clickTrackingInfo;
    }

    private createTopicPromoFeature(featureOrder: number, featureIndex: number, status: FeatureStatus): Feature {
        return {
            id: `ft3_tpr${featureIndex}`,
            name: "TopicPromotionLarge",
            position: featureOrder,
            status: status,
            labels: {
                promo_Feature: ["true"],
                promo_FilledSlots: ["1"],
                promo_FeatureNumber: [(featureIndex + 1).toString()]
            }
        }
    }

    private createTopicPromoTileFeature(featureIndex: number, featureTrackingTileLabels: DataContainer, status: FeatureStatus): Feature {
        return {
            id: `ft3_tpr${featureIndex}_tile1`,
            parentId: `ft3_tpr${featureIndex}`,
            name: "TopicPromotionLarge-Tile",
            status: status,
            labels: featureTrackingTileLabels
        };
    }

    private createTopicPromoFeatureMiniAction(featureIndex: number): MiniAction {
        return {
            featureId: `ft3_tpr${featureIndex}`,
            action: "scroll"
        }
    }

    _createTopicPromoTileFeatureMiniAction(featureIndex: number): MiniAction {
        return {
            featureId: `ft3_tpr${featureIndex}_tile1`,
            action: "scroll"
        }
    }

}