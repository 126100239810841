import {Logger} from "./logger";
import {PromoTopicPromo} from "./topicpromo";

window.o_global.eventQBus.emitModuleLoaded("ft3.promo.topicpromo");

window.o_global.eventQBus.on("ft3.promo-topicpromo.init", () => {
    Logger.group("[FT3 promo topicpromo]");
    Logger.count("Topicpromo Invocation");

    const containers: NodeListOf<Element> = document.querySelectorAll('.promo_topicpromo--container');
    [].forEach.call(containers, (container: Element) => {
        if (container.getAttribute("data-tracked") !== "true") {
            container.setAttribute("data-tracked", "true");
            new PromoTopicPromo(container);
        }
    });

    Logger.groupEnd();
});

window.o_global.eventLoader.onAllPreloadScriptsLoaded(95, () => {
    window.o_global.eventQBus.emit("ft3.promo-topicpromo.init")
})
